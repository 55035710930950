import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { formatDistanceToNow } from 'date-fns';

const AllOutstandingPaymentsPage = () => {
  const [paymentData, setPaymentData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:3000/api/all-outstanding-payments');
        const result = await response.json();
        setPaymentData(result.data);
      } catch (error) {
        console.error('Error fetching payment data:', error);
      }
    };

    fetchData();
  }, []);

  const renderTable = (data, title) => {
    if (!data || data.length === 0) return null;
    
    const formatLastSaleDate = (dateString) => {
      if (!dateString) return 'Never';
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true });
    };
    
    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800 mt-7">{title}</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse">
            <thead>
              <tr className="bg-gray-50">
                {title === "Paid Promoters" ? (
                  <>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-gray-700">Name</th>
                    <th className="px-4 py-3 text-right text-sm font-semibold text-gray-700">Last sale date</th>
                  </>
                ) : (
                  <>
                    <th className="px-4 py-3 text-left text-sm font-semibold text-gray-700">Name</th>
                    <th className="px-4 py-3 text-right text-sm font-semibold text-gray-700">New Rev.</th>
                    <th className="px-4 py-3 text-right text-sm font-semibold text-gray-700">Refunds</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {Object.values(data).map((item) => (
                <tr key={item.promoter?.id}>
                  {title === "Paid Promoters" ? (
                    <>
                      <td className="border p-2">{item.promoter?.firstName}</td>
                      <td className="border p-2 text-right">
                        {formatLastSaleDate(item.promoter?.lastSale?.apiCreationDateTime)}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="border p-2">{item.promoter?.firstName}</td>
                      <td className="border p-2">${(item.outstanding / 100).toFixed(2)}</td>
                      <td className="border p-2">${(item.refundedOutstanding / 100).toFixed(2)}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <h1 className="text-2xl font-bold mb-6">All Outstanding Payments</h1>
      
      {paymentData && (
        <>
          {renderTable(paymentData.unpaidPromoters, "Unpaid Promoters")}
          {renderTable(paymentData.paidPromoters, "Paid Promoters")}
          {renderTable(paymentData.promotersWithNoSales, "Promoters With No Sales")}
        </>
      )}
    </Layout>
  );
};

export default AllOutstandingPaymentsPage; 